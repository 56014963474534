<template>

  <div class="column" :class="{ 'pl-0 pr-0': isMobile }">

    <div @touchend="fixActionRestriction">

      <div class="mb-5" v-if="!isMobile">
        <p class="menu-label">Menus</p>
      </div>

      <div class="list_container">

        <Container
          @drop="onMenuDrop"
          @drop-ready="onDropReady"
          :lock-axis="dngSettings.lockAxis"
          :drag-begin-delay="dngSettings.delay"
          :drop-placeholder="dngSettings.dropPlaceholderOptions"
          drag-class="ghost-drag"
          drop-class="ghost-drop"
          :class="{ 'pl-4': !isMobile }"
        >

          <Draggable
            v-for="(menu, menuIndex) in restaurantToUpdate.menus"
            :key="menu._id"
          >

            <div className="menu-container">

              <div
                class="is-flex
                       is-justify-content-space-between
                       is-align-items-center
                       pb-4 pr-2"
              >
                <div
                  class="is-flex is-align-items-center"
                  @click.stop="toggleMenu(menu)"
                  style="cursor: pointer;"
                >
                  <b-icon
                    class="is-pulled-left mr-3"
                    :icon="isMenuExpanded(menu) ? 'menu-up' : 'menu-down'"
                    type="is-secondary"
                  />
                  <span>{{ menu.name }}</span>
                </div>

                <div id="v-step-4">
                  <b-tooltip
                    v-if="!menu.digital"
                    position="is-left"
                    type="is-dark"
                    :delay="200"
                    label="Menu au format image activé"
                  >
                    <b-icon icon="file-image" class="has-text-danger" />
                  </b-tooltip>
                  <b-tooltip
                    v-if="!menu.published"
                    position="is-left"
                    type="is-dark"
                    :delay="200"
                    label="Menu non publié"
                  >
                    <b-icon icon="eye-off" class="has-text-warning" />
                  </b-tooltip>
                  <b-dropdown
                    aria-role="list"
                    class="is-pulled-right"
                    position="is-bottom-left"
                    id="v-step-1"
                  >
                    <template #trigger>
                      <b-icon icon="dots-vertical" style="cursor: pointer;" />
                    </template>
                    <b-dropdown-item
                      aria-role="edit menu"
                      @click="openEditMenuModal(menuIndex, menu)"
                    >
                      <div class="is-flex is-align-items-center">
                        <b-icon icon="pencil" class="mr-4" type="is-primary" />
                        Modifier
                      </div>
                    </b-dropdown-item>
                    <b-dropdown-item
                      aria-role="delete menu"
                      @click="openMenuDeleteDialog(menu._id)"
                    >
                      <div class="is-flex is-align-items-center">
                        <b-icon icon="delete" class="mr-4" type="is-danger" />
                        Supprimer
                      </div>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>

              <div
                :class="{ 'pl-6': !isMobile, 'pl-4': isMobile }"
                v-show="isMenuExpanded(menu)"
              >

                <Container
                  @drop="(e) => onMealDrop(menu._id, e)"
                  @drop-ready="onDropReady"
                  :get-child-payload="getMealPayload(menu)"
                  :group-name="dngSettings.mealsGroupName"
                  :lock-axis="dngSettings.lockAxis"
                  :drag-begin-delay="dngSettings.delay"
                  :drop-placeholder="dngSettings.dropPlaceholderOptions"
                  drag-class="ghost-drag"
                  drop-class="ghost-drop"
                >

                  <Draggable v-for="meal in menu.meals" :key="meal._id">

                    <div
                      class="is-flex
                             is-justify-content-space-between
                             is-align-items-center
                             meal-container
                             pt-2 pb-2 pr-2"
                      :class="selectedCategory
                              && selectedCategory._id === meal._id
                              && 'meal-container__active'"
                    >

                      <div
                        class="is-flex is-align-items-center category-label"
                        @click.stop="onCategoryClick(meal, menu._id)"
                        style="width: 100%;"
                      >
                        <b-icon icon="drag" class="is-pulled-left mr-3" id="v-step-5" />
                        {{ meal.name }}
                      </div>

                      <div class="is-flex is-align-items-center">
                        <b-tooltip
                          v-if="!meal.published"
                          position="is-left"
                          type="is-dark"
                          :delay="200"
                          label="Catégorie non publiée"
                        >
                          <b-icon icon="eye-off" class="has-text-warning" />
                        </b-tooltip>
                        <b-dropdown
                          class="is-pulled-right"
                          position="is-bottom-left"
                          id="v-step-3"
                        >
                          <template #trigger>
                            <b-icon icon="dots-vertical"></b-icon>
                          </template>
                          <b-dropdown-item
                            aria-role="edit item"
                            @click="openEditCategoryModal(menuIndex, meal)"
                          >
                            <div class="is-flex is-align-items-center">
                              <b-icon icon="pencil" class="mr-4" type="is-primary" />
                              Modifier
                            </div>
                          </b-dropdown-item>
                          <b-dropdown-item
                            aria-role="delete item"
                            @click="openCategoryDeleteDialog(menu._id, meal._id)"
                          >
                            <div class="is-flex is-align-items-center">
                              <b-icon
                                icon="delete"
                                class="mr-4"
                                type="is-danger"
                              />
                              Supprimer
                            </div>
                          </b-dropdown-item>
                        </b-dropdown>
                      </div>

                    </div>

                  </Draggable>

                </Container>

                <div v-if="menu.meals.length === 0"
                     class="has-text-centered pb-2 has-text-grey-light">
                  Ce menu ne contient aucune catégorie
                </div>

                <b-button
                  type="is-secondary"
                  @click="createCategoryModalProps.selectedMenu = menu;
                          isCreateCategoryModaleActive = true;"
                  expanded
                  rounded
                  class="mt-5 mb-4"
                  icon-left="plus"
                  id="v-step-2"
                >
                  Ajouter catégorie
                </b-button>

              </div>

            </div>

          </Draggable>

        </Container>

        <div v-if="!restaurant.menus.length" class="has-text-grey-light">
          <div class="is-flex is-justify-content-center has-text-centered p-4">
            <p>Pour commencer, veuillez ajouter un nouveau menu à votre restaurant.</p>
          </div>
        </div>

        <div id="v-step-0" class="mt-6 sticky-btn">
          <b-button
            type="is-primary"
            @click="isCreateMenuModaleActive = true"
            expanded
            rounded
            :disabled="restaurant.menus.length >= 10"
            class="is-primary"
            icon-left="plus"
          >
            Ajouter un menu dynamique
          </b-button>
          <b-button
            type="is-primary"
            @click="imagePDFMenu = true; isCreateMenuModaleActive = true"
            expanded
            rounded
            :disabled="restaurant.menus.length >= 10"
            class="is-secondary mt-3"
            icon-left="plus"
          >
            Ajouter un menu image ou PDF
          </b-button>
        </div>

      </div>

    </div>

    <b-modal
      v-model="isCreateMenuModaleActive"
      has-modal-card
      trap-focus
      :can-cancel="['x']"
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-label="Create menu dialog"
      aria-modal
      :full-screen="isMobile"
    >
      <template>
        <app-create-menu-form
          :isImagePDF="imagePDFMenu"
          @close="closeMenuModal"
          @update-restaurant="onAddMenu"
        />
      </template>
    </b-modal>

    <b-modal
      v-model="isCreateCategoryModaleActive"
      has-modal-card
      trap-focus
      :can-cancel="['x']"
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-label="Create category dialog"
      aria-modal
      :full-screen="isMobile"
    >
      <template #default="props">
        <app-create-category-form
          v-bind="createCategoryModalProps"
          @close="props.close"
          @update-restaurant="onAddCategory"
        />
      </template>
    </b-modal>

    <b-modal
      v-model="isEditCategoryModaleActive"
      has-modal-card
      trap-focus
      :can-cancel="['x']"
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-label="Edit category dialog"
      aria-modal
      :full-screen="isMobile"
    >
      <template #default="props">
        <app-edit-category-form
          v-bind="editCategoryModalProps"
          @close="props.close"
          @update-restaurant="restaurantToUpdate = $event; $emit('restaurant-save');"
        />
      </template>
    </b-modal>

    <b-modal
      v-model="isEditMenuModaleActive"
      has-modal-card
      trap-focus
      :can-cancel="['x']"
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-label="Edit menu dialog"
      aria-modal
      :full-screen="isMobile"
    >
      <template #default="props">
        <app-edit-menu-form
          v-bind="editMenuModalProps"
          @close="props.close"
          @update-restaurant="restaurantToUpdate = $event; $emit('restaurant-save');" />
      </template>
    </b-modal>

  </div>

</template>

<script>
import { Container, Draggable } from 'vue-smooth-dnd';
import cloneDeep from 'lodash.clonedeep';
import CreateMenuForm from '@/components/back_office/CreateMenuForm.vue';
import CreateCategoryForm from '@/components/back_office/CreateCategoryForm.vue';
import EditCategoryForm from '@/components/back_office/EditCategoryForm.vue';
import EditMenuForm from '@/components/back_office/EditMenuForm.vue';

export default {
  props: {
    restaurant: {
      type: Object,
      default: null,
    },
    selectedCategory: {
      type: Object,
      default: null,
    },
    selectedMenu: {
      type: Object,
      default: null,
    },
    draggable: {
      type: Boolean,
      default: false,
    },
    isMobile: {
      type: Boolean,
    },
  },
  components: {
    Container,
    Draggable,
    'app-create-menu-form': CreateMenuForm,
    'app-create-category-form': CreateCategoryForm,
    'app-edit-category-form': EditCategoryForm,
    'app-edit-menu-form': EditMenuForm,
  },
  data: () => ({
    expandedMenus: [],
    isCreateMenuModaleActive: false,
    isCreateCategoryModaleActive: false,
    isEditCategoryModaleActive: false,
    imagePDFMenu: false,
    createCategoryModalProps: {
      selectedMenu: null,
    },
    editCategoryModalProps: {
      restaurant: null,
      menuIndex: null,
      category: null,
    },
    isEditMenuModaleActive: false,
    editMenuModalProps: {
      restaurant: null,
      menuIndex: null,
      menu: null,
    },
    dngSettings: {
      menusGroupName: 'menus',
      mealsGroupName: 'meals',
      ghostClass: 'ghost',
      lockAxis: 'y',
      nonDragAreaSelector: '.non-draggable',
      dropPlaceholderOptions: {
        delay: 200,
        className: 'drop-preview',
        animationDuration: '150',
        showOnTop: true,
      },
      upperDropPlaceholderOptions: {
        className: 'cards-drop-preview',
        animationDuration: '150',
        showOnTop: true,
      },
    },
  }),
  created() {
    const firstMenu = this.restaurant.menus[0];

    if (firstMenu) {
      this.expandedMenus.push(firstMenu._id);
    }
  },
  methods: {
    openEditMenuModal(menuIndex, menu) {
      this.editMenuModalProps.restaurant = this.restaurantToUpdate;
      this.editMenuModalProps.menuIndex = menuIndex;
      this.editMenuModalProps.menu = menu;
      this.isEditMenuModaleActive = true;
    },
    openEditCategoryModal(menuIndex, category) {
      this.editCategoryModalProps.restaurant = this.restaurantToUpdate;
      this.editCategoryModalProps.menuIndex = menuIndex;
      this.editCategoryModalProps.category = category;
      this.isEditCategoryModaleActive = true;
    },
    onAddCategory($event) {
      this.restaurantToUpdate = $event.restaurant;
      this.onCategoryClick($event.category);
    },
    onAddMenu($event) {
      this.restaurantToUpdate = $event.restaurant;
      this.expandedMenus.push($event.menu._id);
    },
    confirmDelete(title, message, confirmText, cb) {
      this.$buefy.dialog.confirm({
        title,
        message,
        cancelText: 'Annuler',
        confirmText,
        type: 'is-danger',
        hasIcon: false,
        onConfirm: cb,
      });
    },
    openCategoryDeleteDialog(menuId, categoryId) {
      this.confirmDelete(
        'Suppression catégorie',
        `
          <div class="has-text-justified">
            Étes vous sûr de vouloir supprimer cette catégorie ? <br>
            Cette action est définitive,
            les produits associés seront supprimés.
          </div>
        `,
        'Supprimer',
        () => this.onCategoryDelete(menuId, categoryId),
      );
    },
    async onCategoryDelete(menuId, categoryId) {
      const r = this.restaurantToUpdate;
      try {
        const { data } = await this.$http.delete(
          `${process.env.VUE_APP_API_URL}/restaurants/${r._id}/menus/${menuId}/meals/${categoryId}`,
        );

        this.restaurantToUpdate = data;
        this.$emit('reset-selected');
        this.$buefy.toast.open({
          message: 'Restaurant mis à jour.',
          type: 'is-success',
          position: 'is-bottom',
        });
      } catch (err) {
        this.$buefy.toast.open({
          indefinite: true,
          message: 'Impossible de supprimer la catégorie.',
          position: 'is-bottom',
          type: 'is-danger',
        });
      }
    },
    openMenuDeleteDialog(menuId) {
      this.confirmDelete(
        'Suppression menu',
        `
          <div class="has-text-justified">
            Étes vous sûr de vouloir supprimer ce menu ? <br>
            Cette action est définitive,
            les catégories et produits associés seront supprimés.
          </div>
        `,
        'Supprimer',
        () => this.onMenuDelete(menuId),
      );
    },
    async onMenuDelete(menuId) {
      const r = { ...this.restaurantToUpdate };

      try {
        await this.$http.delete(
          `${process.env.VUE_APP_API_URL}/restaurants/${r._id}/menus/${menuId}`,
        );

        r.menus = r.menus.filter((m) => m._id !== menuId);
        this.restaurantToUpdate = r;
        this.$emit('reset-selected');
        this.$buefy.toast.open({
          message: 'Restaurant mis à jour.',
          type: 'is-success',
          position: 'is-bottom',
        });
      } catch (err) {
        this.$buefy.toast.open({
          indefinite: true,
          message: 'Impossible de supprimer le menu.',
          position: 'is-bottom',
          type: 'is-danger',
        });
      }
    },
    onCategoryClick(meal, idOfMenu) {
      this.$emit('category-click', meal, idOfMenu);
    },
    toggleMenu(menu) {
      const isExpanded = this.isMenuExpanded(menu);

      this.expandedMenus = isExpanded ? (
        this.expandedMenus.filter((id) => id !== menu._id)
      ) : (
        [...this.expandedMenus, menu._id]
      );

      // if (!isExpanded && menu.meals.length) {
      //   this.onCategoryClick(menu.meals[0]);
      // }
    },
    isMenuExpanded(menu) {
      return this.expandedMenus.find((id) => menu._id === id) !== undefined;
    },
    onDropReady() {
      // eslint-disable-next-line no-unused-expressions
      window?.navigator?.vibrate?.(200);
    },
    onMenuDrop(dropResult) {
      if (dropResult.removedIndex !== dropResult.addedIndex) {
        const sortedMenus = this.applyDrag(
          cloneDeep(this.restaurantToUpdate.menus),
          dropResult,
        );

        this.restaurantToUpdate = {
          ...this.restaurantToUpdate,
          menus: sortedMenus,
        };
        this.$emit('restaurant-save');
      }
    },
    onMealDrop(menuId, dropResult) {
      if (dropResult.removedIndex !== null || dropResult.addedIndex !== null) {
        const sortedMenus = this.restaurantToUpdate.menus;
        const menu = sortedMenus.filter((m) => m._id === menuId)[0];
        const menuIndex = sortedMenus.indexOf(menu);
        const newMenu = { ...menu };

        newMenu.meals = this.applyDrag(newMenu.meals, dropResult);
        sortedMenus.splice(menuIndex, 1, newMenu);

        this.restaurantToUpdate = {
          ...this.restaurantToUpdate,
          menus: sortedMenus,
        };
        if (dropResult.addedIndex !== null) {
          this.$emit('restaurant-save');
        }
      }
    },
    getMealPayload(menu) {
      // eslint-disable-next-line max-len
      return (index) => menu.meals[index];
    },
    applyDrag(arr, dragResult) {
      const { removedIndex, addedIndex, payload } = dragResult;

      if (removedIndex === null && addedIndex === null) return arr;

      const result = [...arr];
      let itemToAdd = payload;

      if (removedIndex !== null) {
        // eslint-disable-next-line prefer-destructuring
        itemToAdd = result.splice(removedIndex, 1)[0];
      }
      if (addedIndex !== null) {
        result.splice(addedIndex, 0, itemToAdd);
      }
      return result;
    },
    // fix scroll freeze (library bug)
    fixActionRestriction() {
      document.body.classList.remove(
        'smooth-dnd-no-user-select',
        'smooth-dnd-disable-touch-action',
      );
    },
    closeMenuModal() {
      this.isCreateMenuModaleActive = false;
      this.imagePDFMenu = false;
    },
  },
  computed: {
    restaurantToUpdate: {
      get() {
        return this.restaurant;
      },
      set(v) {
        this.$emit('restaurant-update', v);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
  .fill-remaining-space {
    flex: 1 1 auto;
  }

  .ghost-drag {
    transition: transform .3s ease;
    transform: rotateZ(5deg);
    cursor: grab;
  }

  .ghost-drop {
    transition: transform .3s ease-in-out;
    transform: rotateZ(0deg)
  }

  ::v-deep .drop-preview {
    background-color: rgba(150, 150, 200, 0.1);
    border: 1px dashed rgb(118, 138, 212);
    border-radius: 5px;
    margin: 5px;
  }

  .smooth-dnd-container.vertical > .smooth-dnd-draggable-wrapper {
    overflow: visible;
  }

  .meal-container {
    cursor: pointer;
    &:hover {
      box-shadow: 0 5px 20px 0 rgba(0,0,0,.05);
      cursor: pointer;
    }
  }

  .meal-container__active {
    background-color: hsl(206, 70%, 96%);
  }

  .list_container {
    position: relative;
    height: 72vh;
    overflow-y: auto;
  }

  .sticky-btn {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
  }

  // .category-label {
  //   white-space: nowrap;
  //   overflow: hidden;
  //   text-overflow: ellipsis;
  // }

  @media(max-width: $tablet) {
    .list_container {
      height: auto;
    }
  }
</style>