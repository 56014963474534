<template>
  <div class="modal-card">

    <header class="modal-card-head">
      <p class="modal-card-title m-0">Nouveau menu</p>
      <button
        type="button"
        class="delete"
        @click="$emit('close')"
      />
    </header>

    <section class="modal-card-body">

      <div v-if="!isUploadPage" class="form">

        <b-field
          label="Nom"
          custom-class="is-small has-text-weight-light has-text-grey"
        >
          <b-input
            v-model="form.name"
            placeholder="ex: Menu midi, Burgers, Salades..."
            ref="nameInput"
            maxlength="200"
            rounded
          />
        </b-field>

        <b-field>
          <b-switch v-model="form.published" type="is-secondary">
            Publié (par défaut le menu sera immédiatement visible par vos clients)
          </b-switch>
        </b-field>

      </div>

      <menu-upload
        v-else
        :restaurantId="restaurant.id"
        :menuId="restaurant.menuId"
      />

    </section>

    <footer class="modal-card-foot is-flex is-justify-content-flex-end">
      <b-button label="Annuler" @click="$emit('close')" />
      <b-button
        v-if="isImagePDF && !isUploadPage"
        label="Suivant"
        type="is-primary"
        @click="create"
        :disabled="!isFormValid"
        icon-left="content-save"
        :loading="loading"
      />
      <b-button
        v-else
        :label="isImagePDF ? 'Terminer' : 'Créer'"
        type="is-primary"
        @click="isImagePDF ? finish() : create()"
        :disabled="!isFormValid"
        icon-left="content-save"
        :loading="loading"
      />
    </footer>

  </div>
</template>

<script>
import MenuUpload from '../MenuUpload.vue';

export default {
  components: { MenuUpload },
  props: {
    isImagePDF: {
      type: Boolean,
    },
  },
  data: () => ({
    form: {
      name: '',
      published: true,
    },
    loading: false,
    error: null,
    isUploadPage: false,
    restaurant: {
      id: null,
      menuId: null,
    },
  }),
  computed: {
    isFormValid() {
      return this.form.name.trim().length > 0 && this.form.name.length <= 200;
    },
  },
  mounted() {
    this.$refs.nameInput.focus();
  },
  methods: {
    async create() {
      const { restaurantId } = this.$route.params;
      const menu = {
        name: this.form.name,
        digital: !this.isImagePDF,
        published: this.form.published,
      };

      this.loading = true;
      try {
        const res = await this.$http.post(
          `${process.env.VUE_APP_API_URL}/restaurants/${restaurantId}/menus`,
          menu,
        );

        this.restaurant.id = restaurantId;
        this.restaurant.menuId = res.data.menu._id;
        this.isUploadPage = this.isImagePDF;

        this.$emit('update-restaurant', { restaurant: res.data.restaurant, menu: res.data.menu });
        if (!this.isImagePDF) {
          this.finish();
        }
      } catch (err) {
        this.$buefy.toast.open({
          indefinite: true,
          message: 'Impossible de créer le menu.',
          position: 'is-bottom',
          type: 'is-danger',
        });
      } finally { this.loading = false; }
    },
    finish() {
      this.$emit('close');
      this.$buefy.toast.open({
        message: 'Restaurant mis à jour.',
        type: 'is-success',
        position: 'is-bottom',
      });
    },
  },
};
</script>