<template>

  <div :class="{ 'column': true, 'is-two-fifths': isMobile }">

    <div>

      <div v-if="!isInsideModal" class="mb-5">
        <p class="menu-label">
          Produit
          <span v-if="dish">:</span>
          <span v-if="dish" class="has-text-weight-bold has-text-black-ter">
            {{ truncateString(dish.name, 40) }}
          </span>
        </p>
      </div>

      <div :class="isInsideModal ? 'item_modal_container' : 'item_container'" v-if="form">

        <div class="is-flex is-justify-content-space-between pt-2 pb-2">
          <div class="switch-label is-flex is-align-items-center">
            Multi prix
          </div>
          <b-field>
            <b-switch
              v-model="form.multiPrice"
              size="is-medium"
              type="is-secondary"
              leftLabel
            />
          </b-field>
        </div>

        <div class="mb-4">
          <p class="label">Prix</p>
          <div class="price_card" v-if="!form.multiPrice">
            <b-field>
              <b-switch
                v-model="form.price.isSpecialOffer"
                :rounded="true"
                :outlined="false"
                :size="''"
                :type="'is-secondary'"
                :left-label="true"
                :passive-type="null"
              >
                Promo
              </b-switch>
            </b-field>

            <b-field :grouped="form.price.isSpecialOffer">

              <b-input
                v-model="form.price.value"
                placeholder="0,00"
                icon-right="currency-eur"
                label="Prix"
                icon-after
                v-currency="vCurrencyConfig"
                custom-class="input_without_border"
                :class="{ 'crossed_out_price': form.price.isSpecialOffer }"
                expanded
              />

              <b-input
                v-model="form.price.specialOffer"
                v-if="form.price.isSpecialOffer"
                placeholder="0,00"
                icon-right="currency-eur"
                label="Prix"
                icon-after
                v-currency="vCurrencyConfig"
                custom-class="input_without_border"
                expanded
              />
            </b-field>
          </div>

          <div v-else class="multi-price_container">
            <div
              class="price_card mb-2"
              v-for="(price, index) in form.prices"
              :key="index"
            >
              <div class="is-flex is-justify-content-space-between">
                <b-field>
                  <b-switch
                    v-model="form.prices[index].isSpecialOffer"
                    :rounded="true"
                    :outlined="false"
                    :size="''"
                    :type="'is-secondary'"
                    :left-label="true"
                    :passive-type="null"
                  >
                    Promo
                  </b-switch>
                </b-field>

                <b-icon
                  type="is-dark"
                  icon="close-box"
                  style="cursor: pointer;"
                  @click.native="deletePrice(index)"
                  v-if="form.prices.length > 2"
                />
              </div>

              <b-field grouped :group-multiline="isMobile">
                <b-field>
                  <b-input
                    v-model="form.prices[index].label"
                    border
                    placeholder="Libellé"
                    custom-class="input_without_border"
                  />
                </b-field>

                <b-field expanded>
                  <b-input
                    v-model="form.prices[index].value"
                    border
                    placeholder="Prix"
                    icon-right="currency-eur"
                    label="Prix"
                    icon-after
                    v-currency="vCurrencyConfig"
                    custom-class="input_without_border"
                    :class="{ 'crossed_out_price': form.prices[index].isSpecialOffer }"
                  />
                </b-field>

                <b-field expanded>
                  <b-input
                    v-model="form.prices[index].specialOffer"
                    v-if="form.prices[index].isSpecialOffer"
                    placeholder="0,00"
                    icon-right="currency-eur"
                    label="Prix"
                    icon-after
                    v-currency="vCurrencyConfig"
                    custom-class="input_without_border"
                  />
                </b-field>
              </b-field>
            </div>

            <div class="is-flex is-justify-content-flex-end mt-4">
              <b-button
                @click="addPrice"
                type="is-secondary"
                outlined
                :disabled="form.prices.length >= 5"
                icon-left="plus"
              >
                Ajouter prix
              </b-button>
            </div>
          </div>
        </div>

        <div
          class="mb-4
                 is-flex
                 is-justify-content-space-between
                 is-flex-wrap-wrap
                 is-flex-direction-row
                 is-justify-content-center"
        >

          <div class="" :style=" { width: isMobile ? '100%' : '45%' }">
            <div v-if="form.image && form.image.url">
              <b-image
                :src="form.image.url"
                alt="Product image"
                lazy
                class="m-0"
              />
            </div>

            <b-field v-else>
              <b-upload
                v-model="dropFile"
                drag-drop
                accept="image/jpeg, image/png"
                native
                @input="onFileChange"
              >
                <section class="section">
                  <div class="content has-text-centered">
                    <p>
                      <b-icon icon="upload" size="is-large" />
                    </p>
                    <p>
                      Déposez votre fichier ici
                    </p>
                  </div>
                </section>
              </b-upload>
            </b-field>
          </div>

          <div
            class="is-flex is-flex-direction-column is-align-items-center"
            :class="{ 'p-4': !isMobile }"
            :style="{ width: !isMobile ? '55%' : '100%' }"
          >
            <div class="py-4">
              <p class="has-text-centered has-text-grey is-size-6">
                Vos clients peuvent voir cette photo. <br />
                Format d'image requis: JPG, PNG jusqu'à 5MB.
              </p>
            </div>
            <b-button
              type="is-light"
              icon-left="delete"
              :loading="isDeleteImageLoading"
              @click="deleteImage"
              v-if="form.image"
              expanded
            >
              Supprimer image
            </b-button>
          </div>

        </div>

        <div class="con-form" v-if="form">

          <b-field v-if="!isInsideModal" label="Nom">
            <b-input
              v-model="form.name"
              placeholder="Nom du produit"
              maxlength="200"
            />
          </b-field>

          <b-field label="Description">
            <b-input
              v-model="form.description"
              placeholder="Description du produit ou liste d'ingrédients"
              maxlength="350"
              rows.native="2"
              type="textarea"
              custom-class="text-area_input"
            />
          </b-field>

          <div class="is-flex is-justify-content-space-between pt-2 pb-2">
            <div class="switch-label is-flex is-align-items-center">
              Publié
            </div>
            <b-field>
              <b-switch
                    v-model="form.published"
                    size="is-medium"
                    type="is-secondary"
                    leftLabel
                  />
            </b-field>
          </div>

          <div>

            <b-dropdown
              v-model="form.tags"
              multiple
              scrollable
              aria-role="list"
              :mobile-modal="false"
              :close-on-click="true"
              @change="$refs.tagsDropdown.toggle()"
              ref="tagsDropdown"
            >

              <template #trigger>
                <b-button
                  type="is-light"
                  icon-right="menu-down"
                  class="mb-4 mt-4"
                  :loading="loadingTagsList"
                >
                  Labels produit
                </b-button>
              </template>

              <b-dropdown-item
                v-for="tag in tags"
                :key="tag._id"
                :value="tag"
                aria-role="listitem"
                :focusable="false"
                :disabled="isTagSelected(tag._id)"
                :class="{ 'is-active': isTagSelected(tag._id) }"
                :style="{
                  'pointer-events': isTagSelected(tag._id) ? 'none' : 'auto',
                }"
              >
                <span>{{ tag.label }}</span>
              </b-dropdown-item>
            </b-dropdown>

            <div v-if="form.tags.length" class="mb-2">
              <b-taglist>
                <b-tag
                  v-for="tag in form.tags"
                  :key="tag._id"
                  class="m-0 mr-3"
                  attached
                  aria-close-label="Remove tag"
                  type="is-dark"
                  closable
                  @close="form.tags = form.tags.filter((t) => t._id !== tag._id)"
                >
                  {{ tag.label }}
                </b-tag>
              </b-taglist>
            </div>

            <div v-else class="has-text-centered">
              <p class="has-text-grey-light">Aucun label produit sélectionné</p>
            </div>

          </div>

          <div>

            <b-dropdown
              v-model="form.allergens"
              multiple
              scrollable
              aria-role="list"
              :mobile-modal="false"
              :close-on-click="true"
              @change="$refs.allergensDropdown.toggle()"
              ref="allergensDropdown"
            >

              <template #trigger>
                <b-button
                  type="is-light"
                  icon-right="menu-down"
                  class="mb-4 mt-4"
                  :loading="loadingAllergenList">
                  Allergènes
                </b-button>
              </template>

              <b-dropdown-item
                v-for="allergen in allergens"
                :key="allergen._id"
                :value="allergen"
                aria-role="listitem"
                :focusable="false"
                :disabled="isAllergenSelected(allergen._id)"
                :class="{
                  'is-active': isAllergenSelected(allergen._id),
                }"
                :style="{
                  'pointer-events': isAllergenSelected(allergen._id) ? (
                    'none'
                  ) : (
                    'auto'
                  ),
                }"
              >
                <span>{{ allergen.label }}</span>
              </b-dropdown-item>
            </b-dropdown>

            <div v-if="form.allergens.length" class="mb-5">
              <b-taglist>
                <b-tag
                  v-for="allergen in form.allergens"
                  :key="allergen._id"
                  class="m-0 mr-3"
                  attached
                  aria-close-label="Remove allergen"
                  type="is-dark"
                  closable
                  @close="form.allergens = form.allergens.filter((a) => {
                    return a._id !== allergen._id
                  })"
                >
                  {{ allergen.label }}
                </b-tag>
              </b-taglist>
            </div>

            <div v-else class="has-text-centered">
              <p class="has-text-grey-light">Aucun allergène sélectionné</p>
            </div>

          </div>

        </div>

        <div
          v-if="form && !isInsideModal"
          class="is-flex
                 is-align-items-center
                 is-justify-content-flex-end
                 is-flex-wrap-wrap
                 is-sticky-bottom
                 pt-2"
        >
          <b-button
            label="Enregistrer"
            type="is-secondary"
            icon-left="content-save"
            class="mr-3"
            rounded
            :disabled="!isFormValid || !isWaitingForModification"
            @click="saveDish"
          />

          <b-button
            label="Annuler"
            type="is-light"
            icon-left="undo-variant"
            class="mr-3"
            rounded
            @click="onCancel"
            :disabled="!isWaitingForModification"
          />

          <b-icon
            type="is-danger"
            icon="delete-outline"
            class="is-clickable"
            @click.native="openDeleteDishDialog"
          />
        </div>

      </div>

      <div v-if="!dish" class="has-text-grey-light has-text-centered p-4">
        <p>Aucun produit sélectionné</p>
      </div>

    </div>

    <b-modal
      v-model="isCropperModaleActive"
      has-modal-card
      trap-focus
      :can-cancel="['x']"
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-label="Image cropper"
      aria-modal
    >
      <template #default="props">
        <app-image-cropper
          v-bind="{ src: imageToCrop }"
          @close="props.close"
          @uploadLogo="uploadLogo"
        />
      </template>
    </b-modal>

  </div>

</template>

<script>
import { CurrencyDirective, parse } from 'vue-currency-input';
import ImageCropper from '@/components/back_office/ImageCropper.vue';
import imageCompression from 'browser-image-compression';
import isequal from 'lodash.isequal';
import clonedeep from 'lodash.clonedeep';
import truncate from 'lodash.truncate';

export default {
  props: {
    dish: {
      type: Object,
      default: null,
    },
    categoryId: {
      type: String,
      default: null,
    },
    menuId: {
      type: String,
      default: null,
    },
    isMobile: {
      type: Boolean,
    },
    isInsideModal: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    'app-image-cropper': ImageCropper,
  },
  directives: {
    currency: CurrencyDirective,
  },
  data: () => ({
    form: null,
    formBeforeUpdate: null,
    allergens: [],
    tags: [],
    loadingAllergenList: true,
    vCurrencyConfig: {
      // locale: 'fr-FR',
      currency: null,
      allowNegative: false,
      precision: 2,
      valueRange: { min: 0 },
    },
    isCropperModaleActive: false,
    imageToCrop: null,
    dropFile: null,
    isDeleteImageLoading: false,
    loadingTagsList: false,
  }),
  watch: {
    dish: {
      immediate: true,
      handler(dishFromProps) {
        if (dishFromProps) {
          // format price values for v-currency input directive
          const formatedDish = this.formatDishToDisplay(dishFromProps);

          this.form = clonedeep(formatedDish);
          this.formBeforeUpdate = clonedeep(this.form);
        } else {
          this.form = null;
        }
      },
    },
  },
  computed: {
    isNameValid() {
      return this.form.name.trim() && this.form.name.length <= 200;
    },
    isDescriptionValid() {
      return this.form.description.length <= 350;
    },
    isUnitPriceValid() {
      const p = this.form.price;

      return p.isSpecialOffer ? (p.value && p.specialOffer) : p.value;
    },
    areMultipriceValid() {
      return this.form.prices
               && this.form.prices.length >= 2
               && this.form.prices.every((p) => {
                 if (p.isSpecialOffer) {
                   return !!p.value && !!p.specialOffer;
                 }
                 return !!p.value;
               });
    },
    isPriceValid() {
      return this.form.multiPrice ? this.areMultipriceValid : this.isUnitPriceValid;
    },
    isFormValid() {
      return this.isNameValid
               && this.isDescriptionValid
               && this.isPriceValid;
    },
    isWaitingForModification() {
      return !isequal(this.form, this.formBeforeUpdate);
    },
  },
  created() {
    this.fetchAllergens();
    this.fetchTags();
  },
  methods: {
    truncateString(str, n = 150) {
      return truncate(str, {
        length: n,
        separator: /,? +/,
      });
    },
    isTagSelected(id) {
      return this.form.tags.some((t) => t._id === id);
    },
    isAllergenSelected(id) {
      return this.form.allergens.some((a) => a._id === id);
    },
    async fetchTags() {
      this.loadingTagsList = true;
      try {
        const { data } = await this.$http.get(
          `${process.env.VUE_APP_API_URL}/tags`,
        );

        this.tags = data;
      } catch (err) {
        this.$buefy.snackbar.open({
          indefinite: true,
          type: 'is-danger',
          message: 'Impossible de récupérer la liste des tags produit.',
        });
      } finally {
        this.loadingTagsList = false;
      }
    },
    onCancel() {
      this.form = clonedeep(this.formBeforeUpdate);
    },
    async onFileChange(file) {
      if (file) {
        // inside modal case
        if (this.isInsideModal) this.saveDish();

        const filesize = ((file.size / 1024) / 1024).toFixed(4); // MB

        console.log('compressedFile original size', filesize, 'MB');

        if (filesize <= 5) {
          this.createImage(file);
        } else {
          this.$buefy.snackbar.open({
            indefinite: true,
            type: 'is-danger',
            message: 'Veuillez sélectionner une image (.jpeg, .png) dont la taille est inférieure à 5MB.',
          });
        }
      }
    },
    async createImage(imageFile) {
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 512, // 128 is ok for dish cards but not for dialog card
        useWebWorker: true,
      };

      try {
        const compressedFile = await imageCompression(imageFile, options);

        // console.log(`compressedFile size before crop ${compressedFile.size / 1024 / 1024} MB`);
        const reader = new FileReader();
        const vm = this;

        reader.onload = (e) => {
          vm.imageToCrop = e.target.result;
          vm.isCropperModaleActive = true;
        };
        reader.readAsDataURL(compressedFile);
      } catch (error) {
        console.log(error);
      }
    },
    uploadLogo(canvas) {
      this.isCropperModaleActive = false;

      try {
        if (canvas) {
          canvas.toBlob(
            async (blob) => {
              const loadingComponent = this.$buefy.loading.open({
                container: null,
              });
              // console.log(`after crop size ${blob.size / 1024 / 1024} MB`);
              const form = new FormData();

              form.append('file', blob);

              const rId = this.$route.params.restaurantId;
              const { menuId, categoryId } = this;
              const dishId = this.dish._id;
              const res = await this.$http.post(
                `${process.env.VUE_APP_API_URL}/restaurants/${rId}/menus/${menuId}/meal/${categoryId}/dishes/${dishId}/upload`,
                form,
              );
              const updatedRestaurant = res.data.restaurant;

              if (updatedRestaurant) {
                const formatedDish = this.formateDishBeforeSave(this.form);

                this.$emit('update-dish', { ...formatedDish, image: res.data.image });
                this.$emit('add-image', updatedRestaurant);
                this.formBeforeUpdate = clonedeep(this.form);

                // inside modal case
                if (this.isInsideModal) this.refeshRestaurant();
              }
              loadingComponent.close();
            },
            this.dropFile.type,
          );
        }
      } catch (err) {
        this.$buefy.snackbar.open({
          indefinite: true,
          type: 'is-danger',
          message: 'Une erreur est survenue lors de l\'enregistrement de l\'image.',
        });
      }
    },
    async deleteImage() {
      this.isDeleteImageLoading = true;

      try {
        const rId = this.$route.params.restaurantId;
        const { menuId, categoryId } = this;
        const dishId = this.dish._id;
        const res = await this.$http.delete(
          `${process.env.VUE_APP_API_URL}/restaurants/${rId}/menus/${menuId}/meals/${categoryId}/dishes/${dishId}/files/delete`,
        );
        const updatedRestaurant = res.data;
        const formatedDish = this.formateDishBeforeSave(this.form);

        // inside modal case
        if (this.isInsideModal) this.refeshRestaurant();

        this.$emit('update-dish', { ...formatedDish, image: undefined });
        this.$emit('delete-image', updatedRestaurant);
        this.formBeforeUpdate = clonedeep(this.form);
      } catch (err) {
        this.$buefy.snackbar.open({
          indefinite: true,
          type: 'is-danger',
          message: 'Une erreur est survenue lors de la tentative de suppression de l\'image.',
        });
      } finally { this.isDeleteImageLoading = false; }
    },
    onCropperClose() {
      this.isCropperModaleActive = false;
    },
    formatDishToDisplay(dish) {
      const formatedDish = {
        ...dish,
        price: dish.price && {
          ...dish.price,
          value: dish.price?.value?.toFixed(2).toString().replace('.', ',') || '',
          specialOffer: dish.price?.specialOffer?.toFixed(2).toString().replace('.', ',') || '',
        },
        prices: dish.prices.map((p) => ({
          ...p,
          value: p.value?.toFixed(2).toString().replace('.', ',') || '',
          specialOffer: p.specialOffer?.toFixed(2).toString().replace('.', ',') || '',
        })),
      };

      return formatedDish;
    },
    formateDishBeforeSave(dish) {
      const formatedDish = {
        ...dish,
        price: dish.price && {
          ...dish.price,
          value: dish.price.value ? parse(dish.price.value.toString(), this.vCurrencyConfig) : 0,
          // eslint-disable-next-line max-len
          specialOffer: dish.price.specialOffer ? parse(dish.price.specialOffer.toString(), this.vCurrencyConfig) : 0,
        },
        prices: dish.prices.map((p) => ({
          ...p,
          value: p.value ? parse(p.value.toString(), this.vCurrencyConfig) : 0,
          specialOffer: p.specialOffer ? parse(p.specialOffer.toString(), this.vCurrencyConfig) : 0,
        })),
      };

      return formatedDish;
    },
    saveDish() {
      this.$emit('save-dish', this.formateDishBeforeSave(this.form));
      this.formBeforeUpdate = clonedeep(this.form);
    },
    openDeleteDishDialog() {
      this.confirmDelete(
        'Suppression produit',
        `
          <div class="has-text-justified">
            Étes vous sûr de vouloir supprimer ce produit ? <br>
            Cette action est définitive.
            Les images associées seront supprimées.
          </div>
        `,
        'Supprimer',
        () => this.deleteDish(),
      );
    },
    async deleteDish() {
      const rId = this.$route.params.restaurantId;
      const { menuId, categoryId } = this;
      const dishId = this.dish._id;

      try {
        const { data } = await this.$http.delete(
          `${process.env.VUE_APP_API_URL}/restaurants/${rId}/menus/${menuId}/meals/${categoryId}/dishes/${dishId}`,
        );

        this.$emit('delete-dish', data);
      } catch (err) {
        this.$buefy.snackbar.open({
          indefinite: true,
          type: 'is-danger',
          message: 'Une erreur est survenue lors de la tentative de suppression du produit.',
        });
      }
    },
    confirmDelete(title, message, confirmText, cb) {
      this.$buefy.dialog.confirm({
        title,
        message,
        confirmText,
        type: 'is-danger',
        cancelText: 'Annuler',
        hasIcon: false,
        onConfirm: cb,
      });
    },
    async fetchAllergens() {
      this.loadingAllergenList = true;
      try {
        const res = await this.$http.get(
          `${process.env.VUE_APP_API_URL}/allergens`,
        );

        this.allergens = res.data;
      } catch (error) {
        this.$buefy.snackbar.open({
          indefinite: true,
          type: 'is-danger',
          message: 'Impossible de récupérer la liste des allergènes.',
        });
      } finally { this.loadingAllergenList = false; }
    },
    addPrice() {
      if (this.form.prices.length < 5) {
        this.form.prices.push({
          label: '',
          value: '',
          currency: 'EUR',
        });
      }
    },
    deletePrice(id) {
      this.form.prices.splice(id, 1);
    },
    async refeshRestaurant() {
      try {
        const res = await this.$http.get(
          `${process.env.VUE_APP_API_URL}/restaurants/${this.$route.params.restaurantId}`,
        );
        this.$store.dispatch('restaurantModule/updateSelectedRestaurant', { restaurant: res.data });
      } catch (err) {
        // eslint-disable-next-line
        console.log(err);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  ::v-deep .text-area_input {
    max-height: 8em !important;
    min-height: 6em !important;
  }
  .item_container {
    position: relative;
    overflow-y: auto;
    height: 72vh;
    padding-bottom: 0 !important;

    ::v-deep .input_without_border {
      box-shadow: none;
      border-top: none;
      border-right: none;
      border-left: none;
      border-radius: 0;
    }
    ::v-deep .crossed_out_price {
      text-decoration: line-through;
    }
    .price_card {
      border: 1px solid #e8ebeb;
      border-radius: 5px;
      padding: 1em;
      background-color: #fff;
    }
    ul {
      margin: 0 !important;

      li {
        display: flex;
        justify-content: space-between;

        .switch {
          margin: 0;
        }
      }
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover
    }
    .is-sticky-bottom {
      position: -webkit-sticky;
      position: sticky;
      bottom: 0;
      z-index: 10;
      background-color: rgb(252, 253, 253);
    }
  }

  .switch-label {
    @extend .label;
    margin-bottom: 0 !important;
  }

  .item_modal_container {
    @extend .item_container;
    overflow-y: visible;
    height: auto;
  }

  @media(max-width: $tablet) {
    .item_container {
      height: auto;
    }
  }
</style>