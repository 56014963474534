<template>
  <div class="modal-card">

    <header class="modal-card-head">
      <p class="modal-card-title m-0">Modification catégorie</p>
      <button type="button"
              class="delete"
              @click="$emit('close')"/>
    </header>

    <section class="modal-card-body">

      <div class="form">

        <b-field label="Nom">
          <b-input v-model="form.name"
                   placeholder="ex: Ex: Entrées, Plats, Desserts..."
                   ref="nameInput"
                   maxlength="200" />
        </b-field>

        <b-field>
          <b-switch v-model="form.published" type="is-secondary">
            Publié
          </b-switch>
        </b-field>

      </div>

    </section>

    <footer class="modal-card-foot is-flex is-justify-content-flex-end">
      <b-button label="Annuler" @click="$emit('close')" />
      <b-button label="Enregitrer"
                type="is-primary"
                @click="edit"
                :disabled="!isFormValid"
                icon-left="content-save"
                :loading="loading" />
    </footer>

  </div>
</template>

<script>
export default {
  props: {
    restaurant: {
      type: Object,
    },
    menuIndex: {
      type: Number,
    },
    category: {
      type: Object,
    },
  },
  data: () => ({
    form: null,
    loading: false,
    error: null,
  }),
  created() {
    this.form = { ...this.category };
  },
  mounted() {
    this.$refs.nameInput.focus();
  },
  computed: {
    isFormValid() {
      return this.form.name.trim().length > 0 && this.form.name.length <= 200;
    },
  },
  methods: {
    async edit() {
      this.loading = true;

      try {
        const r = { ...this.restaurant };

        r.menus[this.menuIndex].meals = r.menus[this.menuIndex].meals.map((c) => (
          c._id !== this.category._id ? c : this.form
        ));
        this.$emit('update-restaurant', r);
        this.$emit('close');
      } catch (err) {
        this.$buefy.toast.open({
          indefinite: true,
          message: 'Impossible de mettre à jour la catégorie.',
          position: 'is-bottom',
          type: 'is-danger',
        });
      } finally { this.loading = false; }
    },
  },
};
</script>