<template>

  <div class="column">

    <div @touchend="fixActionRestriction">

      <div class="mb-5">
        <p class="menu-label">
          Catégorie
          <span v-if="category">:</span>
          <span v-if="category" class="has-text-weight-bold has-text-black-ter">
            {{ truncateString(category.name, 40) }}
          </span>
        </p>
      </div>

      <div class="list_container">

        <Container @drop="onDishDrop"
                   @drop-ready="onDropReady"
                   :lock-axis="dngSettings.lockAxis"
                   :drag-begin-delay="dngSettings.delay"
                   :drop-placeholder="dngSettings.dropPlaceholderOptions"
                   drag-class="ghost-drag"
                   drop-class="ghost-drop"
                   v-if="category">

          <Draggable v-for="dish in category.dishes" :key="dish._id">

            <app-dish-card
              :dish="dish"
              :isShadowless="true"
              :hasBorder="true"
              :isSelected="selectedDish && (dish._id === selectedDish._id)"
              @card-click="onDishClick($event, category._id)"
              class="my-2"
            />

          </Draggable>

        </Container>

        <div v-if="!category"
             class="has-text-grey-light has-text-centered p-4">
            <p>Aucune catégorie sélectionnée</p>
        </div>

        <div v-else-if="category.dishes.length === 0"
             class="has-text-grey-light has-text-centered">
            <div class="is-flex is-justify-content-center">
              <b-icon icon="inbox-arrow-down" size="is-large" />
            </div>
            <div class="is-flex is-justify-content-center has-text-centered p-4">
              <p>
                La catégorie
                <span class="has-text-weight-semibold">{{ category.name }}</span>
                ne contient aucun produit
              </p>
            </div>
        </div>

        <div class="mt-6 sticky-btn">
          <b-button
            type="is-primary"
            expanded
            class="is-primary"
            icon-left="plus"
            rounded
            :disabled="!category"
            @click="isCreateDishModaleActive = true;"
          >
            Ajouter produit
          </b-button>
        </div>

      </div>

    </div>

    <b-modal v-model="isCreateDishModaleActive"
             has-modal-card
             trap-focus
             :can-cancel="['x']"
             :destroy-on-hide="true"
             aria-role="dialog"
             aria-label="Create dish dialog"
             aria-modal
             :full-screen="isMobile">
      <template #default="props">
        <app-create-dish-form
          :categoryId="category._id"
          :selectedMenuId="selectedMenuId"
          :isMobile="isMobile"
          @close="props.close"
          @add-dish="onAddDish"
          @save-dish="$emit('save-dish', $event)"
          @dont-go-next-step="goNextStep = false"
        />
      </template>
    </b-modal>

  </div>

</template>

<script>
import { Container, Draggable } from 'vue-smooth-dnd';
import cloneDeep from 'lodash.clonedeep';
import CreateDishForm from '@/components/back_office/CreateDishForm.vue';
import DishCards from '@/components/shared/DishCard.vue';
import truncate from 'lodash.truncate';

export default {
  props: {
    category: {
      type: Object,
      default: null,
    },
    selectedDish: {
      type: Object,
      default: null,
    },
    selectedMenuId: {
      type: String,
      default: null,
    },
    draggable: {
      type: Boolean,
      default: false,
    },
    isMobile: {
      type: Boolean,
    },
  },
  components: {
    Container,
    Draggable,
    'app-create-dish-form': CreateDishForm,
    'app-dish-card': DishCards,
  },
  data: () => ({
    isCreateDishModaleActive: false,
    dngSettings: {
      menusGroupName: 'category',
      ghostClass: 'ghost',
      lockAxis: 'y',
      nonDragAreaSelector: '.non-draggable',
      dropPlaceholderOptions: {
        delay: 200,
        className: 'drop-preview',
        animationDuration: '150',
        showOnTop: true,
      },
    },
    goNextStep: true,
  }),
  methods: {
    truncateString(str, n = 150) {
      return truncate(str, {
        length: n,
        separator: /,? +/,
      });
    },
    onDishClick(dish, categoryId) {
      this.$emit('dish-click', {
        dish: cloneDeep(dish),
        categoryId,
      });
    },
    onAddDish(dish) {
      this.$emit('update-category', {
        ...this.category,
        dishes: [...this.category.dishes, dish],
      }, null, this.goNextStep);
    },
    onDropReady() {
      // eslint-disable-next-line no-unused-expressions
      window?.navigator?.vibrate?.(200);
    },
    onDishDrop(dropResult) {
      if (dropResult.removedIndex !== dropResult.addedIndex) {
        const sortedDishes = this.applyDrag(
          cloneDeep(this.category.dishes),
          dropResult,
        );
        const updatedCategory = { ...this.category, dishes: sortedDishes };

        this.$emit('reorder-category', updatedCategory);
      }
    },
    applyDrag(arr, dragResult) {
      const { removedIndex, addedIndex, payload } = dragResult;

      if (removedIndex === null && addedIndex === null) return arr;

      const result = [...arr];
      let itemToAdd = payload;

      if (removedIndex !== null) {
        // eslint-disable-next-line prefer-destructuring
        itemToAdd = result.splice(removedIndex, 1)[0];
      }
      if (addedIndex !== null) {
        result.splice(addedIndex, 0, itemToAdd);
      }
      return result;
    },
    // fix scroll freeze (library bug)
    fixActionRestriction() {
      document.body.classList.remove(
        'smooth-dnd-no-user-select',
        'smooth-dnd-disable-touch-action',
      );
    },
  },
  computed: {
    restaurantToUpdate: {
      get() {
        return this.restaurant;
      },
      set(v) {
        this.$emit('restaurant-update', v);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
  .ghost-drag {
    transition: transform .3s ease;
    transform: rotateZ(5deg);
    cursor: grab;
  }
  .ghost-drop {
    transition: transform .3s ease-in-out;
    transform: rotateZ(0deg)
  }
  ::v-deep .drop-preview {
    background-color: rgba(150, 150, 200, 0.1);
    border: 1px dashed rgb(118, 138, 212);
    border-radius: 5px;
    margin: 5px;
  }
  .smooth-dnd-container.vertical > .smooth-dnd-draggable-wrapper {
    overflow: visible;
  }

  .list_container {
    position: relative;
    height: 72vh;
    overflow-y: auto;

    .sticky-btn {
      position: -webkit-sticky;
      position: sticky;
      bottom: 0;
    }
  }

  @media(max-width: $tablet) {
    .list_container {
      height: auto;
    }
  }
</style>