<template>
  <div class="modal-card">

    <header class="modal-card-head">
      <p class="modal-card-title m-0">Nouveau produit</p>
      <button type="button"
              class="delete"
              @click="$emit('close')"/>
    </header>

    <section class="modal-card-body">

      <div class="form">

        <b-field label="Nom">
          <b-input v-model="form.name"
                   placeholder="Nom du produit"
                   ref="nameInput"
                   maxlength="200" />
        </b-field>

        <b-field label="Prix" v-if="!form.multiPrice">
          <b-input v-model="form.price.value"
                   placeholder="0,00"
                   icon-right="currency-eur"
                   label="Prix"
                   icon-after
                   v-currency="vCurrencyConfig"
                   custom-class="input_without_border" />
        </b-field>

        <b-button
          v-if="!moreDetails"
          class="mt-5"
          icon-right="plus"
          expanded
          :disabled="!isFormValid"
          @click="showMoreDetails"
        >
          Plus de détails
        </b-button>

        <dish-edition
          ref="moreDetailsForm"
          v-if="moreDetails"
          :isInsideModal="true"
          :dish="getLastDish"
          :categoryId="categoryId"
          :menuId="selectedMenuId"
          :isMobile="isMobile"
          v-on="$listeners"
        />

      </div>

    </section>

    <footer class="modal-card-foot is-flex is-justify-content-flex-end">
      <b-button label="Annuler" @click="$emit('close')" />
      <b-button label="Créer"
                type="is-primary"
                @click="moreDetails ? saveAndCloseModal() : create()"
                :disabled="!isFormValid"
                icon-left="content-save"
                :loading="loading" />
    </footer>

  </div>
</template>

<script>
import { CurrencyDirective, parse } from 'vue-currency-input';
import DishEdition from './DishEdition.vue';

export default {
  components: { DishEdition },
  directives: {
    currency: CurrencyDirective,
  },
  props: {
    categoryId: {
      type: String,
      default: null,
    },
    selectedMenuId: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    form: {
      name: '',
      description: '',
      price: {
        value: '',
        currency: 'EUR',
      },
      prices: [
        { label: '', value: '', currency: 'EUR' },
        { label: '', value: '', currency: 'EUR' },
      ],
      allergens: [],
      tags: [],
      published: true,
      multiPrice: false,
    },
    loadingAllergenList: true,
    vCurrencyConfig: {
      currency: null,
      allowNegative: false,
      precision: 2,
      valueRange: { min: 0 },
    },
    loading: false,
    loadingTagsList: false,
    error: null,
    moreDetails: false,
    dropFile: null,
    isMobile: false,
    isCropperModaleActive: false,
    lastDish: null,
  }),
  mounted() {
    this.$refs.nameInput.focus();
  },
  computed: {
    isNameValid() {
      return this.form.name.trim() && this.form.name.length <= 200;
    },
    isDescriptionValid() {
      return this.form.description.length <= 350;
    },
    isUnitPriceValid() {
      return this.form.price.value;
    },
    areMultipriceValid() {
      return this.form.prices
               && this.form.prices.length >= 2
               && this.form.prices.every((p) => !!p.value);
    },
    isPriceValid() {
      return this.form.multiPrice ? this.areMultipriceValid : this.isUnitPriceValid;
    },
    isFormValid() {
      return this.isNameValid
             && this.isDescriptionValid
             && this.isPriceValid;
    },
    getLastDish() {
      const menu = this.$store.getters['restaurantModule/restaurant'].menus.find((m) => m._id === this.selectedMenuId);
      const category = menu?.meals.find((c) => c._id === this.categoryId);
      const lastAddedDish = category?.dishes.find((d) => d.name === this.form.name);
      return lastAddedDish;
    },
  },
  methods: {
    isTagSelected(id) {
      return this.form.tags.some((t) => t._id === id);
    },
    isAllergenSelected(id) {
      return this.form.allergens.some((a) => a._id === id);
    },
    create(close = true) {
      this.lastDishName = this.form.name.trim();
      const formatedForm = {
        ...this.form,
        price: {
          ...this.form.price,
          value: parse(
            this.form.price.value && this.form.price.value.toString(),
            this.vCurrencyConfig,
          ),
        },
        prices: this.form.prices.map((p) => ({
          ...p,
          value: parse(p.value, this.vCurrencyConfig),
        })),
      };

      if (this.isFormValid) {
        this.$emit('add-dish', formatedForm);
        if (close) this.$emit('close');
      }
    },
    showMoreDetails() {
      this.$emit('dont-go-next-step');
      this.create(false);
      this.moreDetails = true;
    },
    saveAndCloseModal() {
      this.$refs.moreDetailsForm.saveDish();
      this.$emit('close');
    },
    addPrice() {
      if (this.form.prices.length < 5) {
        this.form.prices.push({
          label: '',
          value: '',
          currency: 'EUR',
        });
      }
    },
    deletePrice(id) {
      this.form.prices.splice(id, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
  ::v-deep .input_without_border {
    box-shadow: none;
    border-top: none;
    border-right: none;
    border-left: none;
    border-radius: 0;
  }
  ul {
    margin: 0 !important;

    li {
      display: flex;
      justify-content: space-between;

      .switch {
        margin: 0;
      }
    }
  }
</style>