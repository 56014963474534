<template>
  <div class="modal-card">

    <header class="modal-card-head">
      <p class="modal-card-title m-0">Modification menu</p>
      <button type="button"
              class="delete"
              @click="$emit('close')"/>
    </header>

    <section class="modal-card-body">

      <div class="form">

        <b-field label="Nom">
          <b-input v-model="form.name"
                   placeholder="ex: Ex: Entrées, Plats, Desserts..."
                   ref="nameInput"
                   maxlength="200" />
        </b-field>

        <b-field>
          <b-switch v-model="form.published" type="is-secondary">
            Publié
          </b-switch>
        </b-field>

        <b-field label="Type de menu">
          <b-radio-button v-model="menuType"
                          native-value="digital"
                          type="is-secondary">
              <b-icon icon="rocket-launch" class="mr-2" />
              <span>Numérique (recommandé)</span>
          </b-radio-button>
          <b-radio-button v-model="menuType"
                          native-value="image"
                          type="is-secondary">
              <b-icon icon="folder-multiple-image" class="mr-2" />
              <span>Image / PDF</span>
          </b-radio-button>
        </b-field>

      </div>

      <app-menu-upload v-if="form && !form.digital"
                       :restaurantId="restaurant._id"
                       :menuId="menu._id" />

    </section>

    <footer class="modal-card-foot is-flex is-justify-content-flex-end">
      <b-button label="Annuler" @click="$emit('close')" />
      <b-button label="Enregistrer"
                type="is-primary"
                @click="edit"
                :disabled="!isFormValid"
                icon-left="content-save"
                :loading="loading" />
    </footer>

  </div>
</template>

<script>
import MenuUpload from '@/components/MenuUpload.vue';

export default {
  components: {
    'app-menu-upload': MenuUpload,
  },
  props: {
    restaurant: {
      type: Object,
    },
    menuIndex: {
      type: Number,
    },
    menu: {
      type: Object,
    },
  },
  data: () => ({
    form: null,
    loading: false,
    error: null,
  }),
  created() {
    this.form = { ...this.menu };
  },
  mounted() {
    this.$refs.nameInput.focus();
  },
  computed: {
    menuType: {
      get() {
        return this.form.digital ? 'digital' : 'image';
      },
      set(newValue) {
        this.form.digital = newValue === 'digital';
      },
    },
    isFormValid() {
      return true;
    },
  },
  methods: {
    async edit() {
      this.loading = true;

      try {
        const r = { ...this.restaurant };

        r.menus[this.menuIndex] = this.form;
        this.$emit('update-restaurant', r);
        this.$emit('close');
      } catch (err) {
        this.$buefy.toast.open({
          indefinite: true,
          message: 'Impossible de mettre à jour le menu.',
          position: 'is-bottom',
          type: 'is-danger',
        });
      } finally { this.loading = false; }
    },
  },
};
</script>