<template>

  <div class="file_upload_container">

    <file-pond name="file"
               ref="pond"
               label-idle="
                 <div class='drop-zone' style='width: 100%; height: 100%;'>
                   <p style='font-size: .8em'>Déposez vos fichiers ici (jpeg, png ou pdf)</p>
                 </div>
               "
               labelFileProcessingError="Échec du téléchargement"
               labelFileProcessing="Téléchargement en cours..."
               labelTapToRetry="cliquer pour réessayer"
               labelTapToCancel="cliquer pour annuler"
               labelFileProcessingComplete="Téléchargement terminé"
               labelFileProcessingAborted="Téléchargement annulé"
               labelTapToUndo="cliquer pour annuler"
               :allow-multiple="true"
               :maxFiles="5"
               :server="server"
               accepted-file-types="image/jpeg, image/png, application/pdf"
               :files="files"
               allowReorder="false"
               @processfile="onFileProcessed"
               @removefile="onProcessFileRevert"/>

  </div>

</template>

<script>
import vueFilePond from 'vue-filepond';
import 'filepond/dist/filepond.min.css';
// Import image preview plugin styles
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
// Import image preview and file type validation plugins
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';

// Create component
const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview);

export default {
  components: {
    FilePond,
  },
  props: {
    restaurantId: {
      type: String,
      required: true,
    },
    menuId: {
      type: String,
      required: true,
    },
  },
  created() {
    this.fetchFiles();
    this.server = {
      url: `${process.env.VUE_APP_API_URL}/restaurants`,
      process: {
        url: `/${this.restaurantId}/menus/${this.menuId}/files/upload`,
        method: 'POST',
        withCredentials: false,
        headers: {
          Authorization: `Bearer ${this.$store.getters['authModule/token']}`,
        },
        onload: (res) => {
          const fileData = JSON.parse(res);
          return fileData.filename;
        },
        ondata: (formData) => formData,
        onerror: () => {
          this.$buefy.snackbar.open({
            indefinite: true,
            type: 'is-danger',
            message: 'La taille du fichier dépasse la taille maximale autorisée.',
          });
        },
      },
      revert: {
        url: `/${this.restaurantId}/menus/${this.menuId}/files/delete`,
        method: 'DELETE',
        withCredentials: false,
        headers: {
          Authorization: `Bearer ${this.$store.getters['authModule/token']}`,
        },
        onerror: (err) => {
          // eslint-disable-next-line
          console.log('revert error', err);
        },
      },
      load: (source, load, error, progress, abort) => {
        fetch(source)
          .then((response) => response.blob())
          .then((myBlob) => load(myBlob))
          .catch((err) => {
            // eslint-disable-next-line
            console.log(err);
          });
        return {
          abort: () => {
            abort();
          },
        };
      },
      remove: async (source, load, error, progress, abort) => {
        const fileNameIndex = source.lastIndexOf('/') + 1;
        // eslint-disable-next-line no-useless-escape
        const filename = source.substr(fileNameIndex).replace(/[\#\?].*$/, '');

        try {
          await fetch(
            `${process.env.VUE_APP_API_URL}/restaurants/${this.restaurantId}/menus/${this.menuId}/files/delete`,
            {
              method: 'DELETE',
              headers: {
                Authorization: `Bearer ${this.$store.getters['authModule/token']}`,
                'Content-type': 'text/plain',
              },
              body: filename,
            },
          );
          load();
        } catch (err) {
          // eslint-disable-next-line
          console.log(err);
        }
        return {
          abort: () => {
            abort();
          },
        };
      },
      restore: null,
      fetch: null,
    };
  },
  data() {
    return {
      files: [],
      files2: [],
      server: null,
      isTipContentDisplayed: false,
    };
  },
  methods: {
    onFileProcessed() {
      this.$buefy.toast.open({
        message: 'Nouveau fichier ajouté au menu.',
        type: 'is-success',
        position: 'is-bottom',
      });
    },
    onProcessFileRevert() {
      this.$buefy.toast.open({
        message: 'Fichier supprimé du menu.',
        type: 'is-success',
        position: 'is-bottom',
      });
    },
    getRestaurantId() {
      return this.$route.params.restaurantId;
    },
    getMenuId() {
      return this.$route.params.menuId;
    },
    async fetchFiles() {
      try {
        const res = await this.$http.get(
          `${process.env.VUE_APP_API_URL}/restaurants/${this.restaurantId}/menus/${this.menuId}/files`,
        );
        const files = res.data.files || [];

        files.map(async (file) => {
          if (file) {
            this.files.push({
              source: `${process.env.VUE_APP_API_URL}/restaurants/${this.restaurantId}/menus/${this.menuId}/image/${file.filename}`,
              options: {
                type: 'local',
              },
            });
          }
        });
      } catch (err) {
        // eslint-disable-next-line
        console.log(err);
      }
    },
  },
};
</script>

<style scoped lang="scss">
  .file_upload_container {
    margin-top: 1em;
  }
  ::v-deep .filepond--root {
    margin-bottom: 0 !important;
  }
  ::v-deep .filepond--drop-label {
    cursor: pointer;
  }
  ::v-deep .drop-zone {
    cursor: pointer;
  }
  .fadeHeight-enter-active,
  .fadeHeight-leave-active {
    transition: all 0.2s;
    max-height: 250px;
  }
  .fadeHeight-enter,
  .fadeHeight-leave-to
  {
    opacity: 0;
    max-height: 0px;
  }
</style>