<template>

  <div class="column" v-if="restaurant">

    <div
      class="columns pt-4"
      v-if="!isMobile"
    >

      <app-menus-list
        :restaurant="restaurant"
        :selectedCategory="selectedCategory"
        :draggable="true"
        @category-click="onCategoryClick"
        @restaurant-update="restaurant = $event;"
        @restaurant-save="updateRestaurant();"
        @reset-selected="selectedCategory= null;
                         selectedDish= null;
                         selectedDishCategoryId= null;
                         selectedDishMenuId= null;"
      />

      <app-dishes-list
        :category="selectedCategory"
        :selectedDish="selectedDish"
        :selectedMenuId="selectedMenuId"
        @dish-click="onDishSelect"
        @update-category="onCategoryUpdate($event)"
        @reorder-category="onCategoryUpdate($event)"
        @save-dish="onDishSave($event)"
      />

      <app-dish-edition
        :dish="selectedDish"
        :categoryId="selectedDishCategoryId"
        :menuId="selectedDishMenuId"
        @update-dish="selectedDish = $event"
        @update-restaurant="restaurant = $event; updateRestaurant();"
        @add-image="restaurant = $event; updateSelectedCategory($event);"
        @delete-image="restaurant = $event; updateSelectedCategory($event);"
        @save-dish="onDishSave($event)"
        @delete-dish="onDishDelete($event)"
      />

    </div>

    <b-tabs v-else v-model="activeTab">
      <b-tab-item label="Menus">
        <app-menus-list
          :restaurant="restaurant"
          :selectedCategory="selectedCategory"
          :draggable="true"
          :isMobile="isMobile"
          @category-click="onCategoryClick"
          @restaurant-update="restaurant = $event;"
          @restaurant-save="updateRestaurant();"
        />
      </b-tab-item>
      <b-tab-item
        :visible="selectedCategory !== null"
        label="Produits"
        :disabled="!selectedCategory"
      >
        <app-dishes-list
          :category="selectedCategory"
          :selectedDish="selectedDish"
          :selectedMenuId="selectedMenuId"
          :isMobile="isMobile"
          @dish-click="onDishSelect"
          @update-category="onCategoryUpdate"
        />
      </b-tab-item>
      <b-tab-item
        :visible="selectedDish !== null"
        label="Fiche produit"
        :disabled="!selectedDish"
      >
        <app-dish-edition
          :dish="selectedDish"
          :categoryId="selectedDishCategoryId"
          :menuId="selectedDishMenuId"
          :isMobile="isMobile"
          @update-dish="selectedDish = $event"
          @update-restaurant="restaurant = $event; updateRestaurant();"
          @add-image="restaurant = $event; updateSelectedCategory($event);"
          @delete-image="restaurant = $event; updateSelectedCategory($event);"
          @save-dish="onDishSave($event)"
          @delete-dish="onDishDelete($event)"
        />
      </b-tab-item>
    </b-tabs>

  </div>

</template>

<script>
import MenusList from '@/components/back_office/MenusList.vue';
import DishesList from '@/components/back_office/DishesList.vue';
import DishEdition from '@/components/back_office/DishEdition.vue';
import cloneDeep from 'lodash.clonedeep';
import truncate from 'lodash.truncate';

export default {
  components: {
    'app-menus-list': MenusList,
    'app-dishes-list': DishesList,
    'app-dish-edition': DishEdition,
  },
  data: () => ({
    activeTab: 0,
    restaurant: null,
    selectedCategory: null,
    selectedDish: null,
    selectedDishCategoryId: null,
    selectedDishMenuId: null,
    selectedMenuId: null,
    windowInnerWidth: window.innerWidth,
  }),
  created() {
    const { restaurantId } = this.$route.params;

    this.fetchRestaurant(restaurantId);
  },
  mounted() {
    window.addEventListener('resize', this.onResize);
  },
  computed: {
    isMobile() {
      return this.windowInnerWidth < 768;
    },
  },
  methods: {
    truncateString(str, n = 150) {
      return truncate(str, {
        length: n,
        separator: /,? +/,
      });
    },
    onResize() {
      this.windowInnerWidth = window.innerWidth;
    },
    goToClientView() {
      const { restaurantId } = this.$route.params;
      const routeData = this.$router.resolve({
        name: 'Home',
        params: { restaurantId },
      });

      window.open(routeData.href, '_blank');
    },
    async updateRestaurant() {
      try {
        const { restaurant } = this;
        const { data } = await this.$http.put(
          `${process.env.VUE_APP_API_URL}/restaurants/${restaurant._id}`,
          restaurant,
        );

        this.restaurant = data;
        this.updateSelectedCategory(data);
        this.$store.dispatch('restaurantModule/updateSelectedRestaurant', { restaurant: data });
        this.$buefy.toast.open({
          message: 'Restaurant mis à jour.',
          type: 'is-success',
          position: 'is-bottom',
        });
      } catch (err) {
        this.$buefy.snackbar.open({
          indefinite: true,
          type: 'is-danger',
          message: 'Une erreur est survenu lors de l\'enregistrement des menus.',
        });
      }
    },
    updateSelectedCategory(restaurant) {
      if (this.selectedCategory) {
        restaurant.menus.forEach((menu) => {
          menu.meals.forEach((meal) => {
            if (meal._id === this.selectedCategory._id) {
              this.selectedCategory = meal;
            }
          });
        });
      }
    },
    onDishSelect({ dish, categoryId }, goNextStep = true) {
      this.selectedDish = dish;
      this.selectedDishCategoryId = categoryId;

      const menu = this.restaurant.menus.find((m) => (
        m.meals.some((meal) => meal._id === categoryId)
      ));

      this.selectedDishMenuId = menu && menu._id;
      if (goNextStep) this.activeTab = 2;
    },
    onCategoryClick(category, menuIdOfCategory) {
      this.selectedMenuId = menuIdOfCategory;
      this.selectedCategory = category;
      this.activeTab = 1;
    },
    async onDishSave(dish) {
      this.selectedDish = dish;
      const updatedMenus = this.restaurant.menus.map((menu) => {
        const idx = menu.meals.findIndex((meal) => meal.dishes.some((d) => d._id === dish._id));

        if (idx > -1) {
          // eslint-disable-next-line no-param-reassign
          menu.meals[idx] = {
            ...menu.meals[idx],
            dishes: menu.meals[idx].dishes.map((d) => (d._id === dish._id ? dish : d)),
          };
          this.selectedCategory = menu.meals[idx];
        }
        return menu;
      });

      this.restaurant.menus = updatedMenus;
      this.updateRestaurant();
    },
    onDishDelete(updatedRestaurant) {
      this.restaurant = updatedRestaurant;
      this.selectedDish = null;
      this.selectedDishCategoryId = null;
      this.selectedDishMenuId = null;
      this.updateSelectedCategory(this.restaurant);
    },
    async onCategoryUpdate(updatedCategory, reorder = false, goNextStep = true) {
      const updatedMenus = this.restaurant.menus.map((menu) => {
        const categoryIndex = menu.meals.findIndex((meal) => meal._id === updatedCategory._id);

        if (categoryIndex > -1) {
          // eslint-disable-next-line no-param-reassign
          menu.meals[categoryIndex] = updatedCategory;
        }
        return menu;
      });

      this.restaurant.menus = updatedMenus;
      await this.updateRestaurant();

      if (!reorder) {
        // open last added dish of edited category
        this.onDishSelect({
          dish: cloneDeep(this.selectedCategory.dishes[this.selectedCategory.dishes.length - 1]),
          categoryId: this.selectedCategory._id,
        }, goNextStep);
      }
    },
    async fetchRestaurant(restaurantId) {
      const loading = this.$buefy.loading.open({
        container: null,
      });

      try {
        const res = await this.$http.get(
          `${process.env.VUE_APP_API_URL}/restaurants/${restaurantId}`,
        );
        this.restaurant = res.data;
        this.$store.dispatch('restaurantModule/updateSelectedRestaurant', { restaurant: res.data });
      } catch (err) {
        // eslint-disable-next-line
        console.log(err);
      } finally { loading.close(); }
    },
  },
};
</script>

<style lang="scss" scoped>
  ::v-deep .tab-content {
    padding: 1em 0;
  }

  ::v-deep .tabs > ul {
    margin: 0;
  }

  ::v-deep .tabs li.is-active a {
    border-bottom-color: #62d4b3;
    color: #62d4b3;
    z-index: 1;
  }
</style>