import { render, staticRenderFns } from "./MenusList.vue?vue&type=template&id=17e1256c&scoped=true&"
import script from "./MenusList.vue?vue&type=script&lang=js&"
export * from "./MenusList.vue?vue&type=script&lang=js&"
import style0 from "./MenusList.vue?vue&type=style&index=0&id=17e1256c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "17e1256c",
  null
  
)

export default component.exports