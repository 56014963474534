<template>
  <div class="modal-card">

    <header class="modal-card-head">
      <p class="modal-card-title m-0">Nouvelle catégorie</p>
      <button type="button"
              class="delete"
              @click="$emit('close')"/>
    </header>

    <section class="modal-card-body">

      <div class="form">

        <b-field label="Nom">
          <b-input v-model="form.name"
                   placeholder="Ex: Entrées, Plats, Desserts..."
                   ref="nameInput"
                   maxlength="200" />
        </b-field>

        <b-field custom-class="is-small">
          <b-switch v-model="form.published" type="is-secondary">
            Visible par le client
          </b-switch>
        </b-field>

      </div>

    </section>

    <footer class="modal-card-foot is-flex is-justify-content-flex-end">
      <b-button label="Annuler" @click="$emit('close')" />
      <b-button label="Créer"
                type="is-primary"
                @click="create"
                :disabled="!isFormValid"
                icon-left="content-save"
                :loading="loading" />
    </footer>

  </div>
</template>

<script>
export default {
  props: {
    selectedMenu: {
      type: Object,
    },
  },
  data: () => ({
    form: {
      name: '',
      published: true,
    },
    loading: false,
    error: null,
  }),
  computed: {
    isFormValid() {
      return this.form.name.trim().length > 0 && this.form.name.length <= 200;
    },
  },
  mounted() {
    this.$refs.nameInput.focus();
  },
  methods: {
    async create() {
      const { restaurantId } = this.$route.params;
      const menuId = this.selectedMenu._id;
      const category = {
        name: this.form.name,
        published: this.form.published,
      };
      this.loading = true;

      try {
        const { data } = await this.$http.post(
          `${process.env.VUE_APP_API_URL}/restaurants/${restaurantId}/menus/${menuId}/meals`,
          category,
        );

        this.$emit('update-restaurant', {
          restaurant: data.restaurant,
          category: data.meal,
        });
        this.$emit('close');
        this.$buefy.toast.open({
          message: 'Restaurant mis à jour.',
          type: 'is-success',
          position: 'is-bottom',
        });
      } catch (err) {
        this.$buefy.toast.open({
          indefinite: true,
          message: 'Impossible de créer la catégorie.',
          position: 'is-bottom',
          type: 'is-danger',
        });
      } finally { this.loading = false; }
    },
  },
};
</script>